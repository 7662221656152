import { PickAnAttribute } from './PickAnAttribute.js';
import { FormatDate } from './Date.js';
import { exportJobMilestones } from './KpiMilestones.js';
import { Round } from './Round.js';
import { Path } from './Path.js';
import { DateTime } from 'luxon';
import { GetJobData } from './GetJobData.js';

export const AppExport = {
  /**
   * Generates a CSV export of certain app data and returns the CSV as a blob.
   *
   * data
   *      jobs                Array      List of job keys in include in export
   *      companyAttributes   Object     Attributes of utility company
   *      companies           Object     photoheight/companies
   *      attachers           Object     utilityInfo/utilityCompany/attachers
   *      overviewAttributes  Array     Attributes shown on the overview page
   *      poleAttributes      Array     Attributes shown on the poles
   *      newAttachTypes      Array     Picklist for new_attach_type attribute
   *
   * funcs
   *      firebase            Object     Firebase class
   *      formatDate
   *      updateProgress
   *
   * options
   *      type                String     'Poles' or 'Apps'
   */
  /* global firebase */
  export: async function (data, funcs, options) {
    data = data || {};
    funcs = funcs || {};
    options = options || {};
    data.attachers = data.attachers || {};

    // Return if data is missing.
    if (!data.jobs || !data.companyAttributes || !data.companies || !data.attachers || !funcs.firebase) return;

    // Special case for KPI Milestones (no job data needed)
    if (options.type == 'KPI Milestones') {
      const rows = await exportJobMilestones(data.jobs, data.attachers, { jobPermissions: data.jobSummary });
      const exportInfo = await FirebaseWorker.ref(
        `photoheight/company_space/${data.userGroup}/models/export_models/google_sheets/exports/kpi_milestones`
      )
        .once('value')
        .then((s) => s.val());
      if (exportInfo?.template_sheet_id) {
        const cells = this.rowsToCells(rows);
        return new Promise((resolve) =>
          funcs.excelExports.requestExport(
            {
              exportName: 'kpi_milestones',
              spreadsheetId: exportInfo.template_sheet_id,
              customRootFolderId: exportInfo.root_folder_id,
              saveType: 'copy',
              title: data.fileName,
              workbookData: [
                {
                  title: 'Data Export',
                  data: cells
                }
              ]
            },
            'google sheets',
            resolve
          )
        );
      }
      return rows.map((x) => x.join(',')).join('\r\n');
    }

    // Special case for Cost Causer Splits (no job data needed)
    if (options.type == 'Cost Causer Splits') {
      const rows = [];
      const header = [];
      // const cells = {};
      // let rowCounter = 2;
      let counter = 1;
      for (const jobId of data.jobs) {
        // cells['A' + rowCounter] = jobs[jobId]?.name;
        const parentJob = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/parent_job`)
          .once('value')
          .then((s) => s.val());
        const jobIdToUse = data.jobSummary[jobId]?.metadata?.original_engineering_job ?? parentJob?.job_id ?? jobId;
        const applications = await FirebaseWorker.ref(`photoheight/jobs/${jobIdToUse}/applications`)
          .once('value')
          .then((s) => s.val());
        const moreThanOnePPLApp = Object.values(applications ?? {}).filter((x) => x.app_company == 'ppl') > 1;
        const nodes = Object.values(
          (await FirebaseWorker.ref(`photoheight/jobs/${jobIdToUse}/nodes`)
            .once('value')
            .then((s) => s.val())) ?? {}
        ).filter(
          (node) => !parentJob || !moreThanOnePPLApp || Object.values(node?.attributes?.submissions ?? {}).some((x) => x.jobId == jobId)
        );
        const appMetadata = data.jobSummary[jobId]?.metadata ?? {};
        const metadata = data.jobSummary[jobIdToUse]?.metadata ?? {};
        const attacherName = data.attachers?.[appMetadata.attachment_owner]?.name ?? '';
        const appStatusDates = await firebase
          .firestore()
          .collectionGroup(`attribute_events_history`)
          .where('entity_id', '==', jobId)
          .where('attribute', '==', 'app_status')
          .get()
          .then((s) => s.docs.map((doc) => ({ $key: doc.id, ...doc.data() })));
        appStatusDates.sort((a, b) => b.set_at?.seconds - a.set_at?.seconds);
        const companyCosts = {};
        const warnings = [];
        // Check to see if this job is set to PCI
        const pci = metadata?.PCI_inspection_count;
        if (pci != null) {
          warnings.push(`Has PCI_inspection_count: ${pci}.`);
        }
        nodes.forEach((node) => {
          let percentCap = parseFloat(Path.get(node, 'attributes.%cap.*')?.replace('%', ''));
          for (let itemKey in node?.attributes?.cost_causer) {
            const item = node.attributes.cost_causer[itemKey];
            const cost = parseFloat(item?.cost?.replace(',', '') || 0) + parseFloat(item?.material_cost?.replace(',', '') || 0);
            for (const id in item.companies) {
              const companyRow = item.companies[id];
              if (companyRow.company) {
                companyCosts[companyRow.company] ??= { total: 0, capital: 0 };
                if (
                  cost &&
                  !header.includes(companyRow.company) &&
                  companyRow.company != 'PPL Company' &&
                  companyRow.company != attacherName
                ) {
                  header.push(companyRow.company);
                }
                const companyTotal = (cost * parseFloat(companyRow.percentage || 0)) / 100;
                if (isNaN(percentCap)) {
                  if (companyTotal > 0) warnings.push(`No %cap for node ${Path.get(node, 'attributes.scid.*')}.`);
                  percentCap = 0;
                }
                companyCosts[companyRow.company].total += companyTotal;
                companyCosts[companyRow.company].capital += (companyTotal * percentCap) / 100;
              }
            }
          }
        });
        if (Object.keys(companyCosts).length == 0 && appMetadata?.pwr_mr_required) {
          warnings.push(`PWR MR Required and No Cost Causer.`);
        }
        const permits = [
          'PennDOT_permit_status',
          'sidewalk_cut_permit',
          'PPL_ROW_status',
          'RxR_permit_status',
          'misc_permit_status',
          'trans_undercrossing_permit_status'
        ];
        rows.push([
          appMetadata.pwr_mr_required ? 'Y' : 'N',
          appMetadata.app_number,
          appMetadata.wo_number,
          this.escapeCSV(attacherName),
          this.getNodeValues(nodes, 'region'),
          this.getNodeValues(nodes, 'op_area'),
          this.getDate(appStatusDates, 'Awaiting Payment (MR CONST)') ||
            this.getDate(appStatusDates, 'Verify Comm MR Completion') ||
            this.getDate(appStatusDates, 'Released'),
          nodes.some(
            (node) =>
              Path.get(node, 'attributes.node_type.*') == 'pole' &&
              permits.some((attr) => ![null, '', 'Unnecessary', undefined].includes(Path.get(node, `attributes.${attr}.*`)))
          )
            ? 'Y'
            : 'N',
          warnings.join(' '),
          companyCosts[attacherName] ? Round(companyCosts[attacherName].total, 2) : '',
          companyCosts[attacherName] ? Round(companyCosts[attacherName].capital, 2) : '',
          companyCosts[attacherName] ? Round(companyCosts[attacherName].total, 2) - Round(companyCosts[attacherName].capital, 2) : '',
          companyCosts['PPL Company'] ? Round(companyCosts['PPL Company'].total, 2) : '',
          companyCosts['PPL Company'] ? Round(companyCosts['PPL Company'].capital, 2) : '',
          companyCosts['PPL Company'] ? Round(companyCosts['PPL Company'].total, 2) - Round(companyCosts['PPL Company'].capital, 2) : '',
          ...header
            .map((company) => {
              if (!companyCosts[company] || company == attacherName) {
                return ['', '', ''];
              }
              const total = Round(companyCosts[company].total, 2);
              const capital = Round(companyCosts[company].capital, 2);
              return [total, capital, total - capital];
            })
            .flat()
        ]);
        // for (const company in companyCosts) {
        //   const cost = companyCosts[company];
        //   if (cost) {
        // const column = this.numberToLetters(header.indexOf(company));
        // cells[column + rowCounter] = Round(cost, 2);
        //   }
        // }
        // rowCounter++;
        // console.log(cells, companyCosts);

        // Update progress
        const progress = counter / data.jobs.length;
        funcs?.updateProgress != null && funcs.updateProgress(progress);
        counter++;
      }
      // header.forEach((company, i) => cells[this.numberToLetters(i) + '1'] = company);
      rows.unshift([
        'PMR Required?',
        'App Number',
        'Work Order',
        'Applicant',
        'Region',
        'Op Area',
        'Date Completed',
        'Permit (Y/N)?',
        'Warnings',
        'Applicant Total',
        'Applicant (Capital$)',
        'Applicant (Expense$)',
        'PPL Total',
        'PPL (Capital$)',
        'PPL (Expense$)',
        ...header
          .map((company) => [
            this.escapeCSV(company + ' Total'),
            this.escapeCSV(company + ' (Capital$)'),
            this.escapeCSV(company + ' (Expense$)')
          ])
          .flat()
      ]);
      return rows.map((x) => x.join(',')).join('\r\n');
    }

    // Split jobs into chunks of 10 and load chunks.
    let chunks = this.chunk(data.jobs, 10);

    // Special case for Cost Export used by LGE
    if (options.type == 'Cost') {
      const rows = [];
      const jobs = {};
      for (let i in chunks) {
        let chunk = chunks[i];
        // Get all the data for the chunk
        await Promise.all(chunk.map((key) => GetJobData(key, ['nodes', 'metadata'], funcs.firebase).then((data) => (jobs[key] = data))));

        // Update progress
        console.info(`Fetched chunk ${parseInt(i) + 1} of ${chunks.length}...`);
        const progress = (parseInt(i) + 1) / chunks.length;
        funcs?.updateProgress != null && funcs.updateProgress(progress);
      }
      let newAttachTypes = {};
      let newAttachTypePicklists = data.companyAttributes?.new_attach_type?.picklists ?? '';
      for (let picklistKey in newAttachTypePicklists)
        newAttachTypePicklists[picklistKey].forEach((item) => (newAttachTypes[item.value] = {}));
      let exportModel = {
        attachment_owner: {
          value: (job) => this.getCompanyName(job?.metadata?.attachment_owner ?? '', 'attachment_owner', data.companies, data.attachers)
        },
        app_name: null,
        app_number: null,
        total_pole_count: {
          value: (job) => Object.values(job.nodes || {}).filter((node) => (node?.attributes?.take_off_pole?.app_added ?? '') != true).length
        },
        current_app_status: {
          metadata: 'app_status'
        },
        pole_count_per_attach_type: {
          headers: {},
          value: (job) => {
            let temp = {};
            Object.values(job.nodes || {})
              .filter((node) => (node?.attributes?.take_off_pole?.app_added ?? '') != true)
              .forEach((node) => {
                let newAttachType = node?.attributes?.new_attach_type?.app_added ?? '';
                if (newAttachType != '') {
                  if (!temp[newAttachType]) temp[newAttachType] = 0;
                  newAttachTypes.pole_count_per_attach_type = true;
                  temp[newAttachType]++;
                }
              });
            return temp;
          }
        },
        date_app_review_invoice_signed: {
          type: 'date'
        },
        review_project: null,
        review_task: null,
        exp_org: {
          label: 'Expense Organization'
        },
        review_fee: null,
        date_mr_cost_estimate_signed: {
          type: 'date',
          metadata: 'date_mr_cost_statement_accepted'
        },
        region: {
          label: 'Region',
          value: (job) => job?.metadata?.majority_region ?? job?.metadata?.majority_operations_center
        },
        project_number: {
          label: 'MR Project'
        },
        mr_task: {
          headers: {},
          maxIndex: 0,
          value: (job) => Object.values(job?.metadata?.mr_task || {})
        }
      };
      let camelCase = (x) =>
        x
          .trim()
          .replace(/_/g, ' ')
          .replace('s+', ' ')
          .split(' ')
          .map((x) => x[0].toUpperCase() + x.slice(1).toLowerCase())
          .join(' ');
      // Flesh out export model.
      for (let key in exportModel) {
        let options = exportModel[key] || { metadata: key };
        if (!options.label) options.label = this.getAttributeName(options.metadata || key, data.companyAttributes) || camelCase(key);
        if (!options.value) options.value = (job) => job?.metadata?.[options.metadata || key] ?? '';
        exportModel[key] = options;
      }
      let exportData = {};
      // Loop through jobs, getting necessary data.
      data.jobs.forEach((jobId) => {
        exportData[jobId] = {};
        for (let key in exportModel) {
          let value = exportModel[key].value(jobs[jobId]);
          if (key == 'pole_count_per_attach_type') {
            exportData[jobId][key] = {};
            for (let newAttachType in value) {
              exportModel[key].headers[newAttachType + ' Count'] = true;
              exportData[jobId][key][newAttachType + ' Count'] = value[newAttachType];
            }
          } else if (key == 'mr_task') {
            exportData[jobId][key] = {};
            value.forEach((task, i) => {
              if (exportModel[key].maxIndex < i) exportModel[key].maxIndex = i;
              exportModel[key].headers[`task_${i + 1}_i_cost`] = true;
              exportData[jobId][key][`task_${i + 1}_i_cost`] = task.task_i_cost;
              exportModel[key].headers[`task_${i + 1}_number`] = true;
              exportData[jobId][key][`task_${i + 1}_number`] = task.task_number;
              exportModel[key].headers[`task_${i + 1}_r_cost`] = true;
              exportData[jobId][key][`task_${i + 1}_r_cost`] = task.task_r_cost;
            });
          } else if (exportModel[key].type == 'date') {
            exportData[jobId][key] = this.formatDate(value);
          } else exportData[jobId][key] = value;
        }
      });
      let headers = [];
      for (let key in exportModel) {
        if (exportModel[key].headers) {
          for (let label in exportModel[key].headers) {
            headers.push(camelCase(label));
          }
        } else headers.push(exportModel[key].label);
      }
      rows.push(headers);
      for (let jobId in exportData) {
        let row = [];
        for (let key in exportModel) {
          if (key == 'pole_count_per_attach_type') {
            for (let header in exportModel[key].headers) {
              row.push(exportData[jobId][key][header] || 0);
            }
          } else if (key == 'mr_task') {
            for (let i = 0; i <= exportModel[key].maxIndex; i++) {
              row.push(exportData?.[jobId]?.[key]?.[`task_${i + 1}_i_cost`] ?? '');
              row.push(exportData?.[jobId]?.[key]?.[`task_${i + 1}_number`] ?? '');
              row.push(exportData?.[jobId]?.[key]?.[`task_${i + 1}_r_cost`] ?? '');
            }
          } else row.push(exportData[jobId][key]);
        }
        row = row.map((val) => `"${val?.toString()?.replace(/"/g, '""') ?? ''}"`);
        rows.push(row);
      }
      return rows.map((x) => x.join(',')).join('\r\n');
    }

    // Get the pole attributes that are not take off pole related.
    let poleAttributes = data.poleAttributes.filter((x) => !['take_off_pole_not_applicable', 'take_off_pole_note'].includes(x.attribute));
    // Special case for KMZ Export
    if (options.type == 'KMZ') {
      // KMZ Export.
      let kml = `<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document><name>KMZ Expo</name><description></description><Style id="pole"><LabelStyle><scale>0.7</scale></LabelStyle><IconStyle><color>ff${data.color.replace(
        /\#/g,
        ''
      )}</color><scale>1</scale><Icon><href>http://maps.google.com/mapfiles/kml/shapes/dot.png</href></Icon></IconStyle></Style>`;
      if (data.poleAttributes) {
        for (let i in chunks) {
          const jobs = {};
          let chunk = chunks[i];
          // Get all the data for the chunk
          await Promise.all(
            chunk.map((key) => GetJobData(key, ['nodes', 'metadata', 'files'], funcs.firebase).then((data) => (jobs[key] = data)))
          );

          // Add the chunk's data to the kml
          chunk.forEach((key) => {
            let job = jobs[key];
            if (job && job.nodes) {
              Object.keys(job.nodes).forEach((key) => {
                let node = job.nodes[key];
                // Skip take off pole.
                if (node?.attributes?.take_off_pole) return;
                if (node.latitude && node.longitude) {
                  let description = '<![CDATA[<html xmlns="http://www.w3.org/1999/xhtml"><body>';
                  description += this.escapeXml(`App Number: ${job?.metadata?.app_number ?? ''}</br>`);
                  description += this.escapeXml(`App Status: ${job?.metadata?.app_status ?? ''}</br>`);
                  description += this.escapeXml(
                    `Attachment Owner: ${this.getCompanyName(
                      job?.metadata?.attachment_owner ?? '',
                      'attachment_owner',
                      data.companies,
                      data.attachers
                    )}</br>`
                  );
                  description += this.escapeXml(`Pole App Number: ${node?.attributes?.pole_app_order?.app_added ?? ''}</br>`);
                  if (data.isUtility) {
                    const region = node?.attributes?.region?.app_added;
                    // TODO (2024-01-10): Remove this fallback when region is standardized.
                    const operationsCenter = node?.attributes?.operations_center?.app_added;
                    const regionInfo = `${region ? `Region: ${region}` : `Operations Center: ${operationsCenter}`}</br>`;
                    description += this.escapeXml(regionInfo);
                  }
                  poleAttributes.forEach((attr) => {
                    let val = node?.attributes?.[attr.attribute]?.app_added ?? '';
                    if (attr.subpath)
                      attr.subpath
                        .split('.')
                        .filter((x) => x)
                        .forEach((x) => (val = val?.[x] ?? ''));
                    let temp = job?.files?.poles?.[key]?.[attr.attribute] || {};
                    if (attr.type == 'file')
                      val = Object.keys(temp)
                        .map((x) => temp[x]?.name)
                        .join(', ');
                    if (val != null && val != '' && val !== false)
                      description += this.escapeXml(`${this.getAttributeName(attr, data.companyAttributes)}: ${val}</br>`);
                  });
                  description += '</body></html>]]>';
                  kml += `<Placemark><name>${this.escapeXml(
                    node?.attributes?.pole_tag?.app_added?.tagtext ?? ''
                  )}</name><description>${description}</description><styleUrl>#pole</styleUrl><Point><coordinates>${node.longitude},${
                    node.latitude
                  }</coordinates></Point></Placemark>`;
                }
              });
            }
          });

          // Update progress
          console.info(`Fetched chunk ${parseInt(i) + 1} of ${chunks.length}...`);
          const progress = (parseInt(i) + 1) / chunks.length;
          funcs?.updateProgress != null && funcs.updateProgress(progress);
        }
      }
      kml += '</Document></kml>';
      return kml;
    }

    // Default export to CSV.
    const rows = [];
    let overviewAttributes = [];
    let statusToSkip = [];
    let headerData = [];

    // Based on the type of export, add the header rows
    if (options.type == 'Poles') {
      if (!poleAttributes) return;

      // Poles Export.
      let temp = [
        'Pole Number',
        'Pole Tag',
        'Pole Owner',
        'App Number',
        'App Status',
        'Attachment Owner',
        'Placed Manually',
        'Latitude',
        'Longitude'
      ];
      if (data.isUtility) temp.push('Region');
      rows.push(temp.concat(poleAttributes.map((attr) => this.getAttributeName(attr, data.companyAttributes))));
    } else if (options.type == 'Apps') {
      if (!data.overviewAttributes || !data.newAttachTypes) return;

      // Application Export.
      overviewAttributes = data.overviewAttributes.filter((x) => !['pole_tag_summary'].includes(x.attribute));
      let header = overviewAttributes
        .map((attr) => this.getAttributeName(attr, data.companyAttributes))
        .concat(data.newAttachTypes.map((x) => x.value));
      header.push('Pole Tags', 'Latitude', 'Longitude', 'JobId');
      rows.push(header);
    } else if (options.type == 'PPL Invoice') {
      let headers = [];
      headerData = [
        { header: 'PPL Agreement Number', data: 'attachment_owner' },
        { header: 'Applicant Name', data: 'attachment_owner' },
        { header: 'Region', data: 'region' },
        { header: 'Area', data: 'op_area' },
        { header: 'App#', data: 'app_number' },
        { header: 'WR#', data: 'wr_number' },
        { header: 'WO#', data: 'wo_number' },
        { header: 'Original Pole Count', data: 'original_pole_count' },
        { header: 'Final Pole Count', data: 'pole_count' },
        { header: 'MR Pole Count', data: 'calc' },
        { header: 'MR Engineering Invoice', data: 'calc' },
        { header: 'MR Engineering Actuals', data: 'mr_engineering_actual' },
        { header: 'MR Engineering Post Construction Inspection Cost', data: 'calc' },
        { header: 'Total MR Const Estimate (COC adjusted)', data: 'calc' },
        { header: 'Capital Dollars', data: 'capital_dollars' },
        { header: 'Expense Dollars', data: 'expense_dollars' },
        { header: 'MR Construction Invoice (Applicant)', data: 'calc' },
        { header: 'Pre-Exising Violation Invoices (Foreign)', data: 'calc' },
        { header: 'Pre-Existing Violation PPL Absorbed Costs', data: 'calc' },
        { header: 'Const Hours Estimate', data: 'construction_hours' },
        { header: 'Flagging Hours Estimate', data: 'flagging_hours' },
        { header: 'App Status', data: 'app_status' }
      ];
      statusToSkip = [
        'Draft',
        'Reviewing for Completeness',
        'Awaiting Payment (MR ENG)',
        'Incomplete',
        'In Review',
        'Data Collection and MR Engineering',
        'Preparing for Virtual Rideout',
        'Submitted',
        'Rejected',
        'Waiting on Permits',
        'Cancellation Requested',
        'Photos Available'
      ];

      headerData.forEach((col) => {
        headers.push(col.header);
      });
      rows.push(headers);
    } else if (options.type == 'Export Timeline') {
      let header = [
        'JobId',
        'App Number',
        'Attachment Owner',
        'App Name',
        'App Status',
        'Pole Count',
        'Survey Duration',
        'Survey Start Date',
        'Currently Calculated Due Date for Survey',
        'Set Due Date for Survey (Upload Required)'
      ];
      rows.push(header);
    }

    // Loop through the chunks of jobs and add the job data to the rows
    for (let i in chunks) {
      const jobs = {};
      const chunk = chunks[i];
      // Get all the data for the chunk
      await Promise.all(
        chunk.map((key) => GetJobData(key, ['nodes', 'metadata', 'files'], funcs.firebase).then((data) => (jobs[key] = data)))
      );

      // Based on the type of export, add the data to the rows
      if (options.type == 'Poles') {
        // Poles Export.
        chunk.forEach((key) => {
          let job = jobs[key];
          if (job && job.nodes) {
            Object.keys(job.nodes).forEach((key) => {
              let node = job.nodes[key];
              // Skip take off pole.
              if (node?.attributes?.take_off_pole) return;
              let row = [
                PickAnAttribute(node.attributes, 'pole_app_order'),
                node?.attributes?.pole_tag?.app_added?.tagtext ?? '',
                `"${PickAnAttribute(node.attributes, 'pole_owner') || ''}"`,
                job?.metadata?.app_number ?? '',
                job?.metadata?.app_status ?? '',
                this.getCompanyName(job?.metadata?.attachment_owner ?? '', 'attachment_owner', data.companies, data.attachers),
                node?.placedManually ?? '',
                node?.latitude,
                node?.longitude
              ];
              if (data.isUtility) {
                const region = node?.attributes?.region?.app_added;
                // TODO (2024-01-10): Remove this fallback when region is standardized.
                const operationsCenter = node?.attributes?.operations_center?.app_added;
                row.push(region ?? operationsCenter);
              }
              poleAttributes.forEach((attr) => {
                let val = node?.attributes?.[attr.attribute]?.app_added ?? '';
                if (attr.subpath)
                  attr.subpath
                    .split('.')
                    .filter((x) => x)
                    .forEach((x) => (val = val?.[x] ?? ''));
                let temp = job?.files?.poles?.[key]?.[attr.attribute] || {};
                if (attr.type == 'file')
                  val = Object.keys(temp)
                    .map((x) => temp[x]?.name)
                    .join(', ');
                if (val == null) val = '';
                // Wrap value in quotes and escape any internal quotes.
                row.push(`"${val.toString().replace(/"/g, '""')}"`);
              });
              rows.push(row);
            });
          }
        });
      } else if (options.type == 'Apps') {
        // Application Export.
        chunk.forEach((key) => {
          let job = jobs[key];
          let row = [];
          overviewAttributes.forEach((attr) => {
            let val = job?.metadata?.[attr.attribute] ?? '';
            if (attr.display_as == 'date') val = this.formatDate(val);
            else if (attr.display_as == 'company') val = this.getCompanyName(val, attr.attribute, data.companies, data.attachers);
            else if (attr.display_as == 'boolean') val = val ? 'X' : '';
            if (val == null) val = '';
            // Wrap value in quotes and escape any interal quotes.
            row.push(`"${val.toString().replace(/"/g, '""')}"`);
          });
          if (job && job.nodes) {
            data.newAttachTypes.forEach((type) => {
              row.push(
                Object.keys(job.nodes)
                  .map((x) => job.nodes[x])
                  .filter((node) => (node?.attributes?.new_attach_type?.app_added ?? '') == type.value).length
              );
            });
            let poleTags = Object.values(job.nodes)
              .filter((node) => !node?.attributes?.take_off_pole)
              .sort(
                (a, b) =>
                  parseInt(a?.attributes?.pole_app_order?.app_added ?? '') - parseInt(b?.attributes?.pole_app_order?.app_added ?? '')
              )
              .map((node) => node?.attributes?.pole_tag?.app_added?.tagtext ?? '')
              .join(', ');
            row.push(`"${poleTags}"`);
            // Get Latitude and Longitude from first node with LatLon.
            let p = 0;
            let allPoles = Object.values(job.nodes).sort((a, b) => {
              let aPoleAppOrder = parseInt(a?.attributes?.pole_app_order?.app_added ?? '');
              let bPoleAppOrder = parseInt(b?.attributes?.pole_app_order?.app_added ?? '');
              if (isNaN(aPoleAppOrder) && isNaN(bPoleAppOrder)) return 0;
              if (!isNaN(aPoleAppOrder) && isNaN(bPoleAppOrder)) return -1;
              if (isNaN(aPoleAppOrder) && !isNaN(bPoleAppOrder)) return 1;
              return aPoleAppOrder - bPoleAppOrder;
            });
            let firstPole = null;
            while (!(firstPole?.latitude || firstPole?.latitude === 0) || !(firstPole?.longitude || firstPole?.longitude === 0)) {
              firstPole = allPoles[p];
              p++;
              if (p > allPoles.length) {
                console.error(`No poles in job ${job} have latitude or longitude, defaulting to 0,0`);
                firstPole = { latitude: 0, longitude: 0 };
              }
            }

            row.push(firstPole?.latitude ?? '', firstPole?.longitude ?? '', key);
          }
          rows.push(row);
        });
      } else if (options.type == 'PPL Invoice') {
        for (let jobKey in chunk) {
          let key = chunk[jobKey];
          let job = jobs[key];
          let invoiceData = job?.metadata?.invoice_data ?? '';
          // we only want jobs that don't have a status that's included in statusToSkip
          let includeJob = !statusToSkip.includes(job?.metadata?.app_status ?? '');
          let costCauser;
          if (job && includeJob) {
            await funcs.firebase
              .database()
              .ref(`utility_info/ppl_attachments/attachers/${job.metadata.attachment_owner}/name`)
              .once('value')
              .then(async (applicantName) => {
                // get costCauser data from the job
                costCauser = this.calcCostCauser(job, applicantName.val());
                // build rows from invoice_data attribute
                if (invoiceData) {
                  for (let key in invoiceData) {
                    let row = [];
                    await this.pplInvoiceData(job, invoiceData[key].attributes, costCauser, headerData, row);
                    rows.push(row);
                  }
                }
                // still build out a line item for the job, but it won't have invoice data
                else {
                  let row = [];
                  for (var i = 0; i < headerData.length; i++) {
                    if (headerData[i].data) {
                      if (headerData[i].header == 'PPL Agreement Number') {
                        let attach_owner = job?.metadata?.[headerData[i].data] ?? '';
                        if (attach_owner) {
                          row.push(
                            await funcs.firebase
                              .database()
                              .ref(`utility_info/ppl_attachments/attachers/${attach_owner}/agreement_number`)
                              .once('value')
                              .then((s) => s.val())
                          );
                        } else row.push('');
                      } else if (headerData[i].header == 'Applicant Name') {
                        let attach_owner = job?.metadata?.[headerData[i].data] ?? '';
                        if (attach_owner) {
                          row.push(
                            await funcs.firebase
                              .database()
                              .ref(`utility_info/ppl_attachments/attachers/${attach_owner}/name`)
                              .once('value')
                              .then((s) => {
                                let name = s.val();
                                // if name has a comma in it, surround it with quotes
                                if (name) {
                                  if (name.match(/"|,/)) {
                                    name = '"' + name + '"';
                                  }
                                }
                                return name;
                              })
                          );
                        } else row.push('');
                      } else row.push(job?.metadata?.[headerData[i].data] ?? '');
                    }
                  }
                  rows.push(row);
                }
              });
          }
        }
      } else if (options.type == 'Export Timeline') {
        chunk.forEach((jobId) => {
          let metadata = jobs?.[jobId]?.metadata || {};
          let attachmentOwner = this.getCompanyName(metadata?.attachment_owner ?? '', 'attachment_owner', data.companies, data.attachers);
          let appName = this.escapeCSV(metadata.app_name || '');
          let appStatus = this.escapeCSV(metadata.app_status || '');
          let timeline = metadata.timeline || {};
          let surveyStage = timeline?.stages?.['0'] || {};
          let startDateKey = surveyStage.start_date || timeline.start_date;
          let startDate = new Date(metadata[startDateKey]);
          let startDateString = !isNaN(startDate.getTime()) ? startDate.toLocaleDateString() : '';
          let dueDate = new Date(startDate.getTime());
          dueDate.setDate(dueDate.getDate() + surveyStage.length);
          let dueDateString = !isNaN(dueDate.getTime()) ? dueDate.toLocaleDateString() : '';
          dueDate.setDate(dueDate.getDate() + surveyStage.length);
          rows.push([
            jobId,
            metadata.app_number,
            attachmentOwner,
            appName,
            appStatus,
            metadata.pole_count,
            surveyStage.length,
            startDateString,
            dueDateString,
            ''
          ]);
        });
      }

      // Update progress
      console.info(`Fetched chunk ${parseInt(i) + 1} of ${chunks.length}...`);
      const progress = (parseInt(i) + 1) / chunks.length;
      funcs?.updateProgress != null && funcs.updateProgress(progress);
    }

    // return the rows as a CSV string
    return rows.map((x) => x.join(',')).join('\r\n');
  },

  async pplInvoiceData(job, invoiceData, costCauser, headerData, row) {
    for (var i = 0; i < headerData.length; i++) {
      if (headerData[i].data) {
        // this is a value that we need to calculate
        if (headerData[i].data == 'calc') {
          if (headerData[i].header == 'MR Pole Count') {
            if (this.isOldApp(job.name)) row.push(job?.metadata?.mr_pole_count ?? '');
            else row.push(invoiceData?.mr_pole_count ?? '');
          } else if (headerData[i].header == 'MR Engineering Invoice') {
            row.push((invoiceData?.original_pole_count ?? '') * 200);
          } else if (headerData[i].header == 'MR Engineering Post Construction Inspection Cost') {
            let field_hours = (invoiceData?.PCI_field_billable_hours ?? '') * 65;
            let office_hours = (invoiceData?.PCI_office_billable_hours ?? '') * 75;
            let surveys = (invoiceData?.PCI_surveys ?? '') * 5 * (invoiceData?.final_pole_count ?? '');
            row.push(field_hours + office_hours + surveys);
          } else if (headerData[i].header == 'Total MR Const Estimate (COC adjusted)') {
            if (this.isOldApp(job.name)) row.push(invoiceData?.mr_construction_estimate ?? '');
            else {
              row.push(costCauser.totalMakeReadyCost);
            }
          } else if (headerData[i].header == 'MR Construction Invoice (Applicant)') {
            if (this.isOldApp(job.name)) row.push(invoiceData?.mr_construction_invoice_applicant ?? '');
            else {
              row.push(costCauser.attacherCost);
            }
          } else if (headerData[i].header == 'Pre-Exising Violation Invoices (Foreign)') {
            if (this.isOldApp(job.name)) row.push(invoiceData?.pre_existing_violation_invoices_foreign ?? '');
            else {
              row.push(costCauser.existingForeignCost);
            }
          } else if (headerData[i].header == 'Pre-Existing Violation PPL Absorbed Costs') {
            if (this.isOldApp(job.name)) row.push(invoiceData?.pre_existing_violation_ppl_costs ?? '');
            else {
              row.push(costCauser.existingPPLCost);
            }
          } else row.push('');
        }
        // this is a value that we can pull straight from metadata (for the most part)
        else {
          if (headerData[i].header == 'PPL Agreement Number') {
            let attach_owner = job?.metadata?.[headerData[i].data] ?? '';
            if (attach_owner) {
              row.push(
                await FirebaseWorker.ref(`utility_info/ppl_attachments/attachers/${attach_owner}/agreement_number`)
                  .once('value')
                  .then((s) => s.val())
              );
            } else row.push('');
          } else if (headerData[i].header == 'Applicant Name') {
            let attach_owner = job?.metadata?.[headerData[i].data] ?? '';
            if (attach_owner) {
              row.push(
                await FirebaseWorker.ref(`utility_info/ppl_attachments/attachers/${attach_owner}/name`)
                  .once('value')
                  .then((s) => {
                    let name = s.val();
                    // if name has a comma in it, surround it with quotes
                    if (name) {
                      if (name.match(/"|,/)) {
                        name = '"' + name + '"';
                      }
                    }
                    return name;
                  })
              );
            } else row.push('');
          } else if (
            [
              'WR#',
              'WO#',
              'MR Engineering Actuals',
              'Capital Dollars',
              'Expense Dollars',
              'Const Hours Estimate',
              'Original Pole Count',
              'Flagging Hours Estimate'
            ].includes(headerData[i].header)
          ) {
            row.push(invoiceData?.[headerData[i].data] ?? '');
          } else row.push(job?.metadata?.[headerData[i].data] ?? '');
        }
      } else {
        row.push('');
      }
    }
  },

  escapeXml: function (unsafe) {
    if (unsafe == null) return unsafe;
    return unsafe.replace(/[<>&'"]/g, function (c) {
      switch (c) {
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '&':
          return '&amp;';
        case "'":
          return '&apos;';
        case '"':
          return '&quot;';
      }
    });
  },
  calcCostCauser: function (job, applicantName) {
    let totalMakeReadyCost = 0;
    let attacherCost = 0;
    let existingPPLCost = 0;
    let existingForeignCost = 0;
    let nodes = job.nodes || {};

    // run through each node in the job
    for (let nodeKey in nodes) {
      // get the costCauser details about each node
      let costCauser = PickAnAttribute(job.nodes[nodeKey].attributes, 'cost_causer');
      // if there is cost causer info, parse out it's data
      if (costCauser) {
        let laborCost = parseFloat(costCauser.cost || 0);
        let materialCost = parseFloat(costCauser.material_cost || 0);
        let totalCost = laborCost + materialCost;
        totalMakeReadyCost += totalCost;
        // Loop through the companies in the cost causer
        for (let companyKey in costCauser.companies) {
          // Get the company listed in the attribute
          let company = costCauser.companies[companyKey].company;
          // Get the company's percentage
          let companyPercentage = parseFloat(costCauser.companies[companyKey].percentage || 0);
          // Create a record for the company
          if (company) {
            let cost = totalCost * (companyPercentage / 100);
            if (company == 'PPL Company') existingPPLCost += cost;
            else if (company == applicantName) attacherCost += cost;
            else existingForeignCost += cost;
          }
        }
      }
    }

    return {
      totalMakeReadyCost: totalMakeReadyCost,
      attacherCost: attacherCost,
      existingPPLCost: existingPPLCost,
      existingForeignCost: existingForeignCost
    };
  },
  isOldApp: function (name) {
    return window.config.appName != 'ppl-kws';
  },
  formatDate: function (time) {
    return time ? FormatDate(parseInt(time)) : '';
  },
  getAttributeName: function (item, companyAttributes) {
    return item.label || (companyAttributes?.[item.attribute]?.label ?? '');
  },
  getCompanyName: function (key, attr, companies, attachers) {
    if (attr == 'attachment_owner') return `"${(attachers?.[key]?.name ?? '').replace(/[,]/g, ',')}"`;
    else if (attr == 'creator') return `"${(companies?.[key]?.name ?? '').replace(/[,]/g, ',')}"`;
  },
  chunk: function (arr, chunkSize) {
    // widgets.chunk.js
    let temp = [];
    for (let i = 0; i < Math.ceil(arr.length / chunkSize); i++) temp.push(arr.slice(chunkSize * i, chunkSize * (i + 1)));
    return temp;
  },
  rowsToCells(rows, { startingRow = 1, startingColumn = 'A' } = {}) {
    let rowCounter = startingRow;
    const cells = {};
    rows.forEach((row) => {
      let columnCounter = startingColumn;
      row.forEach((cell) => {
        cells[columnCounter + rowCounter] = cell;
        const nextColumnValue = columnCounter.endsWith('Z') ? 'AA' : String.fromCharCode(columnCounter.at(-1).charCodeAt(0) + 1);
        columnCounter = columnCounter.replace(/.$/, nextColumnValue);
      });
      rowCounter++;
    });
    return cells;
  },
  numberToLetters(num) {
    let letters = '';
    while (num >= 0) {
      letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
      num = Math.floor(num / 26) - 1;
    }
    return letters;
  },
  getNodeValues(nodes, attribute) {
    return nodes
      .reduce((list, node) => {
        const val = Path.get(node, `attributes.${attribute}.*`);
        if (val && !list.includes(val)) list.push(val);
        return list;
      }, [])
      .join(';');
  },
  getDate(dates, type) {
    const timestamp = dates.find((x) => x.value == type)?.set_at?.seconds;
    return timestamp ? DateTime.fromMillis(timestamp * 1000).toISODate() : '';
  },
  escapeCSV(text) {
    return text.match(/[\"\,]/g) ? `"${text.replaceAll('"', '""')}"` : text;
  }
};
